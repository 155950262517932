import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule} from '@angular/forms';
import { AuthGuard } from './auth/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ToastrModule } from 'ngx-toastr';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';  
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component'; 
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EditorModule } from '@tinymce/tinymce-angular';
// import { CKEditorModule } from 'ckeditor4-angular';
// import {ConnectionServiceModule, ConnectionServiceOptionsToken} from 'ng-connection-service';


import { LoginComponent } from './pages/login/login.component';
import { GetStartComponent } from './pages/get-start/get-start.component';
import { SideMenuComponent } from './pages/include/side-menu/side-menu.component';
import { HeaderComponent } from './pages/include/header/header.component';
import { FooterComponent } from './pages/include/footer/footer.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { ProductsListComponent } from './pages/products-list/products-list.component';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { CancellationComponent } from './pages/cancellation/cancellation.component';
import { CreateOrderComponent } from './pages/create-order/create-order.component';
import { SmsGatewayComponent } from './pages/sms-gateway/sms-gateway.component';
import { PaymentGatewayComponent } from './pages/payment-gateway/payment-gateway.component';
import { ManageCityComponent } from './pages/manage-city/manage-city.component';
import { UserRolesComponent } from './pages/user-roles/user-roles.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { SeoComponent } from './pages/seo/seo.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DynamicScriptComponent } from './pages/dynamic-script/dynamic-script.component';
import { StaticPagesComponent } from './pages/static-pages/static-pages.component';
import { StaticPagesFormComponent } from './pages/static-pages-form/static-pages-form.component';
import { EditOrderComponent } from './pages/edit-order/edit-order.component';
import { PromoCodeComponent } from './pages/promo-code/promo-code.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomersEditComponent } from './pages/customers-edit/customers-edit.component';
import { CustomersDetailsComponent } from './pages/customers-details/customers-details.component';
import { DomainComponent } from './pages/domain/domain.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { RestaurantComponent } from './pages/restaurant/restaurant.component';
import { ImportCatalogueComponent } from './pages/import-catalogue/import-catalogue.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { HomeComponent } from './pages/home/home.component';
import { RestaurantListComponent } from './pages/restaurant-list/restaurant-list.component';
import { AreaListComponent } from './pages/area-list/area-list.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { VerifyRestaurantComponent } from './pages/verify-restaurant/verify-restaurant.component';
import { TimingsComponent } from './restaurant/timings/timings.component';
import { OutsideClickDirective } from './directives/outside-click.directive'; 
import { ExportService } from './services/export.service';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        GetStartComponent,
        SideMenuComponent,
        HeaderComponent,
        FooterComponent,
        ForgotPasswordComponent,
        OrderListComponent,
        ProductsListComponent,
        ConfirmationDialogComponent,
        CatalogueComponent,
        CheckoutComponent,
        DeliveryComponent,
        CancellationComponent,
        CreateOrderComponent,
        SmsGatewayComponent,
        PaymentGatewayComponent,
        ManageCityComponent,
        UserRolesComponent,
        AccountSettingsComponent,
        SeoComponent,
        AnalyticsComponent,
        DynamicScriptComponent,
        StaticPagesComponent,
        StaticPagesFormComponent,
        EditOrderComponent,
        PromoCodeComponent,
        CustomersComponent,
        CustomersEditComponent,
        CustomersDetailsComponent,
        DomainComponent,
        DiscountComponent,
        RestaurantComponent,
        ImportCatalogueComponent,
        NotFoundComponent,
        LogoutComponent,
        ResetPasswordComponent,
        RegistrationComponent,
        OrderSummaryComponent,
        HomeComponent,
        RestaurantListComponent,
        AreaListComponent,
        VerifyRestaurantComponent,
        TimingsComponent,
        OutsideClickDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        Ng2SearchPipeModule,
        CKEditorModule,
        NgSelectModule,
        NgxDropzoneModule,
        BsDatepickerModule.forRoot(),
        EditorModule,
        // ConnectionServiceModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [ApiService, AuthGuard, ConfirmationDialogService,ExportService,
        // {
        //   provide: ConnectionServiceOptionsToken,
        //   useValue: <ConnectionServiceOptions>{
        //     enableHeartbeat: false,
        //     heartbeatUrl: '/assets/ping.json',
        //     requestMethod: 'get',
        //     heartbeatInterval: 3000
        //   }
        // }
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService, multi: true /*, useValue: { duration: 2600, verticalPosition: 'top' }*/
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }

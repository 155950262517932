import { Component, OnInit } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { Router } from "@angular/router"; 
@Component({
  selector: "app-manage-city",
  templateUrl: "./manage-city.component.html",
  styleUrls: ["./manage-city.component.scss"],
})
export class ManageCityComponent implements OnInit {
  modalReference: NgbModalRef;
  emiratesForm: FormGroup;
  areaForm: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  areaView: boolean = false;
  edit: boolean = false;
  inputStaticCharges: boolean = false;
  emiratesArray;
  emiratesList;
  emirateObj;
  areaObj;
  areaArray;
  emirateId;
  areaId;
  isEnabled;
  page = 10;
  perPage = 80;
  totalItems: any;
  data;
  ngLoader: boolean = false;
  id;
  currentPage;
  totalPages;
  isAddMoreEmiratesChecked = false;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    if (!ok) return;
    this.emiratesForm = this.fb.group({
      name: ["", Validators.required],
      staticCharges: ["", Validators.pattern("^[0-9]*$")],
      isEnabled: [true, []],
    });
    this.areaForm = this.fb.group({
      parentEmirateId: ["", Validators.required],
      name: ["", Validators.required],
      isEnabled: ["true"],
    });
    this.getEmiratesList();
    this.getEmiratesList1();
  }

  get f() {
    return this.emiratesForm.controls;
  }

  get f1() {
    return this.areaForm.controls;
  }

  editEmirate(content, id) {
    console.log("Edit emirate called.");
    this.emirateId = id;
    // this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    // this.modalReference.result.then((result) => {
    // }, (reason) => {
    // });
    if (this.emirateId) {
      this.edit = true;
      this.ngLoader = true;
      this.ApiService.getEmirateData(this.emirateId).subscribe(
        (result) => {
          console.log("Api loaded...");
          this.ngLoader = false;
          this.emirateObj = result["item"];
          console.log("Data is ");
          console.log(JSON.stringify(this.emirateObj));
          this.emiratesForm.patchValue({
            name: this.emirateObj.name,
            isEnabled: this.emirateObj.isEnabled,
            staticCharges: this.emirateObj.staticCharges,
          });
          if(this.emirateObj.staticCharges) {
            this.inputStaticCharges = true;
          } else {
            this.inputStaticCharges = false;
          }
          
          this.open(content, true);
        },
        (error) => {
          this.ngLoader = false;
        }
      );
    }
  }

  editArea(content, id) {
    this.areaId = id;
    // this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    // this.modalReference.result.then((result) => {
    // }, (reason) => {

    // });
    if (this.areaId) {
      this.edit = true;
      this.ngLoader = true;
      this.ApiService.getAreaData(this.areaId).subscribe(
        (result) => {
          this.ngLoader = false;
          this.areaObj = result["item"];
          console.log("Result is ");
          console.log(JSON.stringify(result));
          this.areaForm.patchValue({
            name: this.areaObj.name,
            parentEmirateId: this.areaObj.parentEmirateId,
          });
          if (this.areaObj.isEnabled == true) {
            this.isEnabled = true;
          }
        },
        (error) => {
          this.ngLoader = false;
        }
      );
    }
  }

  open(content, isEdit) {
    this.edit = false;
    if (isEdit) {
      this.edit = true;
    }
    else {      
      this.emiratesForm.patchValue({ isEnabled: true });
    }
    this.submitted = false;
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {}
    );
    if (!isEdit) {
      this.emiratesForm.reset();
      this.emiratesForm.patchValue({ isEnabled: true });
      this.inputStaticCharges = false;
      
    } 
  }
  
  toggleDeliveryCharges(event) {
    if (event.target.checked == true) {
      this.inputStaticCharges = true;
    } else {
      this.emiratesForm.patchValue({ staticCharges: 0 });      
      this.inputStaticCharges = false;
      // set value of static charges to zero if required
    }
  }

  emirateSubmit() {
    var data = this.emiratesForm.value;
    console.log(this.f.staticCharges.errors);
    this.submitted = true;
    if (this.emiratesForm.invalid) {
      this.clicked = false;
      return;
    }
    if (this.edit) {
      console.log("Edtit called...");
      this.ngLoader = true;

      console.log(this.emiratesForm.value)
      this.ApiService.updateEmirate(
        this.emiratesForm.value,
        this.emirateId
      ).subscribe(
        (result) => {
          console.log("Result is ");
          console.log(JSON.stringify(result));
          this.ngLoader = false;
          if (result["success"] == 1) {
            this.toastr.success(result["message"]);
            this.emiratesForm.reset();
            this.modalReference.close();
          } else {
            this.toastr.warning(result["message"]);
          }
          this.modalReference.close();
          this.submitted = false;
          this.getEmiratesList();
          this.getEmiratesList1();
        },
        (error) => {
          this.ngLoader = false;
        }
      );
    } else {
      console.log(this.emiratesForm.value);
      this.ngLoader = true;
      this.ApiService.createEmirate(this.emiratesForm.value).subscribe(
        (result) => {
          this.ngLoader = false;
          if (result["success"] == 1) {
            this.toastr.success(result["message"]);
            this.emiratesForm.reset();
            this.modalReference.close();
          } else {
            this.toastr.warning(result["message"]);
          }
          this.submitted = false;
          this.getEmiratesList();
          this.getEmiratesList1();
        },
        (error) => {
          this.ngLoader = false;
          this.toastr.error(error.error.message);
        }
      );
    }
  }

  addMoreEmirates() {
    this.submitted = false;
    this.inputStaticCharges = false;
    this.emiratesForm.reset();
  }
  onAddMoreEmiratesChange(event) {
    this.isAddMoreEmiratesChecked = event.target.checked;
  }
  getEmiratesList1() {
    this.ngLoader = true;
    this.ApiService.getEmiratesList().subscribe(
      (data) => {
        this.ngLoader = false;
        this.emiratesList = data["items"];
        this.currentPage = data["page"];
        this.totalPages = data["totalPages"];
        console.log("Daata is ....");
        console.log(JSON.stringify(data));
        console.log(data);
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }

  getEmiratesList() {
    (this.page = 1), (this.perPage = 80);
    this.ngLoader = true;
    this.ApiService.getEmiratesListPage(this.page, this.perPage).subscribe(
      (result) => {
        this.ngLoader = false;
        this.data = result;
        this.emiratesArray = this.data["items"];
        this.currentPage = this.data["page"];
        this.totalPages = this.data["totalPages"];
        this.totalItems = this.data["totalItems"];
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  pageChange(page) {
    this.page = page;
    this.ngLoader = true;
    this.ApiService.getEmiratesListPage(this.page, this.perPage).subscribe(
      (result) => {
        this.ngLoader = false;
        this.totalItems = result["totalItems"];
        this.emiratesArray = result["items"];
      },
      (error) => {
        this.ngLoader = false;
      }
    );
  }

  areaSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.areaForm.invalid) {
      this.clicked = false;
      return;
    }
    this.emirateId = this.areaForm.value.parentEmirateId;
    if (this.edit) {
      this.ngLoader = true;
      this.ApiService.updateArea(this.areaForm.value, this.areaId).subscribe(
        (result) => {
          this.ngLoader = false;
          this.toastr.success("Successfully updated");
          this.modalReference.close();
          this.areaForm.reset();
          this.viewArea(this.emirateId);
        },
        (error) => {
          this.ngLoader = false;
        }
      );
    } else {
      this.ngLoader = true;
      this.ApiService.createArea(this.areaForm.value).subscribe(
        (result) => {
          this.ngLoader = false;
          this.toastr.success("Successfully saved");
          this.modalReference.close();
          this.areaForm.reset();
          this.viewArea(this.emirateId);
        },
        (error) => {
          this.ngLoader = false;
        }
      );
    }
  }
  onEmiratesList() {
    this.areaView = false;
  }
  viewArea(id) {
    this.emirateId = id;
    this.areaView = true;
    (this.page = 1), (this.perPage = 80);
    this.ngLoader = true;
    this.ApiService.getAreaListPage(
      this.emirateId,
      this.page,
      this.perPage
    ).subscribe(
      (result) => {
        this.ngLoader = false;
        this.areaArray = result["items"];
      },
      (error) => {
        this.ngLoader = false;
        this.toastr.error(error.error.message);
      }
    );
  }

  deleteEmirate(id) {
    this.emirateId = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.confirmDelete();
      }
    });
  }

  confirmDelete() {
    this.ApiService.deleteEmirate(this.emirateId).subscribe(
      (result) => {
        this.toastr.success("Successfully deleted");
        this.getEmiratesList();
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  deleteArea(id) {
    this.areaId = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.confirmAreaDelete();
      }
    });
  }

  confirmAreaDelete() {
    this.ApiService.deleteArea(this.areaId).subscribe((result) => {
      this.toastr.success("Successfully deleted");
    });
  }
}

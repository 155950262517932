import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {  NgbCalendar,  NgbDate,  NgbDateStruct,  NgbInputDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent implements OnInit {
  discountCreateFrom: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  modalReference: NgbModalRef;
  productList: any = [];
  showProductInputs: boolean = false;
  model: NgbDateStruct;
  discountList: any = []; 
  isModalTypeEdit: any;
  updateId: any;
  discountDetails: any;
  page = 1;
  perPage = 8;
  pagination: any;
  today= new Date();
  id;
  ngLoader;
  constructor(
   
    private ApiService: ApiService,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private fb: FormBuilder, 
    private router: Router,
  ) {

 
    this.discountCreateFrom = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      value: ['', [Validators.required]],
      // restaurantId: ['', [Validators.required]],
      restaurantItemId: [''],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      discountType: ['percentage', [Validators.required]],

      isDiscount: [true, [Validators.required]],
      isPromoCode: [false, [Validators.required]],
      isItemDiscount: [false, [Validators.required]],
      isSubTotalDiscount: [false, [Validators.required]],
      isDeliveryChargeDiscount: [false, [Validators.required]], 

      prmotionAppliedOn:['', [Validators.required]],
     
    })
   }
   get f() {
    return this.discountCreateFrom.controls;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
 

  open(content,modalId, type ){
    this.updateId = modalId
    this.isModalTypeEdit = type
    console.log(type)

    this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    this.modalReference.result.then((result) => {
     
    }, (reason) => {
      // console.log('closemodal')
      this.discountCreateFrom.reset();

    });

    if( this.isModalTypeEdit == "update"){
      this.ngLoader = true;
      this.ApiService.getDiscountDetails(modalId).subscribe((res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          console.log(res);
         this.discountDetails = this.ApiService.addDatesFromUnixTs(res['restaurantOffer']); 
         this.discountCreateFrom.patchValue({name:  this.discountDetails.name})
         this.discountCreateFrom.patchValue({description:  this.discountDetails.description})
         this.discountCreateFrom.patchValue({value:  this.discountDetails.value})
         this.discountCreateFrom.patchValue({startDate: new Date(this.discountDetails.startDate)});
         this.discountCreateFrom.patchValue({endDate: new Date(this.discountDetails.endDate)});
         this.discountCreateFrom.patchValue({discountType:  this.discountDetails.discountType})
         this.discountCreateFrom.patchValue({isDiscount:  this.discountDetails.isDiscount})
         this.discountCreateFrom.patchValue({isPromoCode:  this.discountDetails.isPromoCode})
         this.discountCreateFrom.patchValue({isItemDiscount:  this.discountDetails.isItemDiscount})
         this.discountCreateFrom.patchValue({isSubTotalDiscount:  this.discountDetails.isSubTotalDiscount})
         this.discountCreateFrom.patchValue({isDeliveryChargeDiscount:  this.discountDetails.isDeliveryChargeDiscount})
         this.discountCreateFrom.patchValue({prmotionAppliedOn:  this.discountDetails.prmotionAppliedOn})  
         this.discountCreateFrom.patchValue({restaurantItemId:  this.discountDetails.restaurantItemId })       
         
         
         if (this.discountDetails.prmotionAppliedOn === 'isItemDiscount') {
          this.showProductInputs = true
          this.getProductListItem();
        }else{
          this.showProductInputs = false
        } 
         
        }
      })
    }
    
  }

  checkPrmotionAppliedOn(event){
    console.log(event.target.value)
    if (event.target.value === 'isItemDiscount') {
       this.showProductInputs = true
    }else{
      this.showProductInputs = false
    } 
    

  }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return;
    }
    var id = localStorage.getItem('restaurantId');
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return;
    }
  } 
  ngOnInit() {
    this.handleRestaurantRegistration()
    this.getProductListItem()
    this.getDiscountList()
  }

  
  getProductListItem() {
    this.ngLoader = true;
    this.ApiService.getProductList('','').subscribe((res: any) => {
      this.productList = res['items']    
      this.ngLoader = false; 
      console.log(this.productList);
    }, error => {
      this.toastr.error(error.error.message);
    })
  }
  getDiscountList() {
    this.ngLoader = true;
    this.ApiService.getDiscountApi().subscribe((res: any) => { 
      let items = [];
      const records = res.items;
      for(let record of records) {
        const item =  this.ApiService.addDatesFromUnixTs(record);
        items.push(item);
      }
      this.discountList = items; 
      this.pagination = res['pagination']
      this.ngLoader = false;
      console.log(res);
    }, error => {
      this.toastr.error(error.error.message);
    })
  }

  enableDisable(customerId,type){ 
    let body = {}
    if(type != true){
       body = {
        isActive: true
      }
    }else{
       body = {
        isActive: false
      }
    }

    this.ngLoader = true;
    this.ApiService.updateDiscountStatus(customerId,body).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastr.success(res.message);
        this.getDiscountList()
      } else {
        this.toastr.warning(res.message);
      }
      this.ngLoader = false;
    }, error => {
      this.toastr.error(error.error.message);
      this.ngLoader = false;
    })
  }
  onDeleteDiscount(id) {     
    Swal.fire({
      title: 'Delete',
      text: "Are you sure you want to delete",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#374abe',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.ngLoader = true;
        this.ApiService.deleteDiscount(id).subscribe((res:any)=>{
          this.toastr.success(res.message);
          this.getDiscountList();
          this.ngLoader = false;
        })
      }
    })
  } 

  onSubmit() {
    if( this.isModalTypeEdit == "update"){
      this.updateDiscount()
    }else{
      this.createDiscount()
    } 
  }

  getFormValidationErrors(form) {
    console.log("Printing validation errors..");
    Object.keys(form.controls).forEach(key => {
  
    const controlErrors: any = form.get(key).errors;
    if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }
  createDiscount(){
    this.submitted = true;
    this.clicked = true;   
    console.log ( this.discountCreateFrom.value)
    this.discountCreateFrom.patchValue({isDiscount: true});
    this.discountCreateFrom.patchValue({isPromoCode: false}); 
    this.discountCreateFrom.patchValue({discountType: 'percentage'}); 
    
    let prmotionAppliedOn = this.discountCreateFrom.value.prmotionAppliedOn
    if (prmotionAppliedOn === 'isItemDiscount') {
      this.discountCreateFrom.patchValue({ isItemDiscount: true });
      this.discountCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.discountCreateFrom.patchValue({ isDeliveryChargeDiscount: false });
    }
    else if (prmotionAppliedOn === 'isSubTotalDiscount') {
      this.discountCreateFrom.patchValue({ isItemDiscount: false });
      this.discountCreateFrom.patchValue({ isSubTotalDiscount: true });
      this.discountCreateFrom.patchValue({ isDeliveryChargeDiscount: false });      
    }
    else if (prmotionAppliedOn === 'isDeliveryChargeDiscount') {
      this.discountCreateFrom.patchValue({ isItemDiscount: false });
      this.discountCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.discountCreateFrom.patchValue({ isDeliveryChargeDiscount: true });
    }    
    if (this.discountCreateFrom.invalid) {
      this.clicked = false;
      console.log("From submission error");
      this.getFormValidationErrors(this.discountCreateFrom);
      return;
    }
    let data = this.discountCreateFrom.value;
    data = this.ApiService.addUnixTsToRequestBody(data);
    this.ngLoader = true;
    this.ApiService.createDiscountApi(data).subscribe((res:any)=>{
      console.log(res);
      if (res.success == 1) {
        this.toastr.success(res.message);
        this.getDiscountList()
        this.modalReference.close();
        this.discountCreateFrom.reset();
        this.submitted = false;
        this.clicked = false;
      } else {
        this.toastr.warning(res.message);
      }
      this.ngLoader = false;
    }, error => {
      this.ngLoader = false;
      this.toastr.error(error.error.message);
    
    })
  }
  updateDiscount(){
    this.submitted = true;
    this.clicked = true;  
    this.discountCreateFrom.patchValue({isDiscount: true});
    this.discountCreateFrom.patchValue({isPromoCode: false}); 
    this.discountCreateFrom.patchValue({discountType: 'percentage'}); 
    console.log ( this.discountCreateFrom.value)
    let prmotionAppliedOn = this.discountCreateFrom.value.prmotionAppliedOn
    if (prmotionAppliedOn === 'isItemDiscount') {
      this.discountCreateFrom.patchValue({ isItemDiscount: true });
      this.discountCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.discountCreateFrom.patchValue({ isDeliveryChargeDiscount: false });
    }
    else if (prmotionAppliedOn === 'isSubTotalDiscount') {
      this.discountCreateFrom.patchValue({ isItemDiscount: false });
      this.discountCreateFrom.patchValue({ isSubTotalDiscount: true });
      this.discountCreateFrom.patchValue({ isDeliveryChargeDiscount: false });      
    }
    else if (prmotionAppliedOn === 'isDeliveryChargeDiscount') {
      this.discountCreateFrom.patchValue({ isItemDiscount: false });
      this.discountCreateFrom.patchValue({ isSubTotalDiscount: false });
      this.discountCreateFrom.patchValue({ isDeliveryChargeDiscount: true });
    }    
    if (this.discountCreateFrom.invalid) {
      console.log("From submission error2");
      this.getFormValidationErrors(this.discountCreateFrom);
      this.clicked = false;
      return;
    }
    let data = this.discountCreateFrom.value;
    this.ngLoader = true;
    data = this.ApiService.addUnixTsToRequestBody(data);
    this.ApiService.updateDiscountDetails(this.updateId,data).subscribe((res:any)=>{
      console.log(res);
      if (res.success == 1) {
        this.toastr.success(res.message);
        this.getDiscountList()
        this.modalReference.close();
        this.discountCreateFrom.reset();
        this.submitted = false;
        this.clicked = false;
      } else {
        this.toastr.warning(res.message);
      }
      this.ngLoader = false;
    }, error => {
      this.ngLoader = false;
      this.toastr.error(error.error.message);
    
    })
  }

}

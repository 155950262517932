// export const BASE_URL = 'http://localhost:8070'; //LOCAL
// export const BASE_URL = 'http://157.245.101.202:8070'; //LIVE
export const BASE_URL = 'https://eato.ae:8070'; //LIVE



// export const BASE_URL_PWA = 'http://localhost:4300'; //LOCAL
export const BASE_URL_PWA = 'http://pwa.eato.ae'; //LIVE






// export const BASE_URL_PWA = 'http://localhost:8070';
// OLD
// export const BASE_URL = 'http://backoffice.api.eato.jinu.live';
// export const BASE_URL_PWA = 'http://eato.jinu.live';

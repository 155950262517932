import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { json } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent implements OnInit {
  seoForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  restaurantData;
  fileName;
  seoImgPath: string | ArrayBuffer;
  result;
  selectedFile;
  imgURL;
  seoHomePageMeta;
  image;
  imageBase;
  id;
  ngLoader;
  restaurantId = JSON.parse(localStorage.getItem('restaurantId'));
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService,  
    private router: Router) { }

    handleRestaurantRegistration() {
      var token =  localStorage.getItem('UserToken');
      var isLoggedIn = false;
      if(token) {
        isLoggedIn = true;
      }
      if(!isLoggedIn) { 
        this.router.navigate(['login']);
        return false;
      }
      var id = localStorage.getItem('restaurantId');
      if(id) {
        id = JSON.parse(id);
      }
      if(id) {
        this.id = id;
      } else {
        console.log("No id found");
        this.router.navigate(['get-started']);
        return false;
      }
      return true;
    } 
  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    this.seoForm = this.fb.group({
      pageTitle: [''],
      pageDescription: [''],
      pageKeywords: [''],
      seoImage: [''],
      seoGoogleTagManagerKey: [''],
      seoRobotsTxt: ['']
    })
    if(!ok) return;
    this.getSeoData();
  }

  getSeoData() {
    
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe(result => {
      console.log("Result is ");
      console.log(JSON.stringify(result));
      this.imageBase = result['imageBase'];
      this.restaurantData = result['item'];
      this.seoHomePageMeta = this.restaurantData.seoHomePageMeta;
      this.image = this.seoHomePageMeta.seoImage
      this.seoForm.patchValue({
        pageTitle: this.restaurantData.seoHomePageMeta.pageTitle,
        pageDescription: this.restaurantData.seoHomePageMeta.pageDescription,
        pageKeywords: this.restaurantData.seoHomePageMeta.pageKeywords,
        seoGoogleTagManagerKey: this.restaurantData.seoGoogleTagManagerKey,
        seoRobotsTxt: this.restaurantData.seoRobotsTxt
      });
      this.ngLoader = false;
    })
  }

  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
      this.toastr.warning(result.message)
      }
  }

  preview(event) {
    if (event.target.files.length === 0)
      return;
    this.selectedFile = event.target.files[0];
    this.seoForm.patchValue({ seoImage: this.selectedFile });
    var mimeType = this.selectedFile.type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.warning('Only images are supported');
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.selectedFile); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

  viewWebApp(){
    var url = this.ApiService.getFrontPageUrl();
    window.open(url, "_blank");
  }
  onSubmit() {
    console.log("Submit called...");
    this.submitted = true;
    this.clicked = true;
    const formData = new FormData();
    var  homePageTitle = this.seoForm.value.pageTitle?this.seoForm.value.pageTitle.trim(): null;
    homePageTitle = homePageTitle?homePageTitle: null;
    
    var  homePageDescription = this.seoForm.value.pageTitle?this.seoForm.value.pageTitle.trim(): null;
    homePageDescription = homePageDescription?homePageDescription: null;

    var  homePageKeywords = this.seoForm.value.pageKeywords?this.seoForm.value.pageKeywords.trim(): null;
    homePageKeywords = homePageKeywords?homePageKeywords: null;

    var  seoGoogleTagManagerKey = this.seoForm.value.seoGoogleTagManagerKey?this.seoForm.value.seoGoogleTagManagerKey.trim(): null;
    seoGoogleTagManagerKey = seoGoogleTagManagerKey?seoGoogleTagManagerKey: null;

    var  seoRobotsTxt = this.seoForm.value.seoRobotsTxt?this.seoForm.value.seoRobotsTxt.trim(): null;
    seoRobotsTxt = seoRobotsTxt?seoRobotsTxt: null;

    //if(homePageTitle)
      formData.append('homePageTitle',this.seoForm.value.pageTitle);
    //if(homePageDescription)
      formData.append('homePageDescription', this.seoForm.value.pageDescription);
    //if(homePageKeywords)
      formData.append('homePageKeywords',this.seoForm.value.pageKeywords);
    //if(seoGoogleTagManagerKey)
      formData.append('seoGoogleTagManagerKey', this.seoForm.value.seoGoogleTagManagerKey);
    //if(seoRobotsTxt)
      formData.append('seoRobotsTxt', this.seoForm.value.seoRobotsTxt);
    formData.append('seoImage', this.seoForm.get('seoImage').value);

    if (this.seoForm.invalid) {
      this.clicked = false;
      return;
    }
    this.ngLoader = true;
    this.ApiService.setupRestaurantsUpdate(this.restaurantId, formData).subscribe(result => {
      console.log(result);
      this.displayErrorsAndSuccess(result);
      this.ngLoader = false;
     })
  }

}

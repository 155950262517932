import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-import-catalogue',
  templateUrl: './import-catalogue.component.html',
  styleUrls: ['./import-catalogue.component.scss']
})
export class ImportCatalogueComponent implements OnInit {
  files: File[] = [];
  ngLoader = false; 
  // sampleCsvFile = "http://vault.eato.jinu.live/eato-products.xlsx";
  sampleCsvFile = 'https://eato.ae/images/eato-product.xlsx';
  fileErrors = null;
  serverErrors: any = [];
  products:any;
  constructor(private ApiService:ApiService,
              private toastr: ToastrService,
              private exportService:ExportService) { 

  }
  ngOnInit() {
  }

  DownloadProductList() {
    this.ngLoader = true;
    console.log("GET PRODUCT LIST ITEM");
    this.ApiService.getRestaurantDetails().subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          // console.log(res);
          this.products = res["item"]; 
          console.log(this.products);
          let selectedProductData :any =[] ;
          let selectedAddonData  :any =[];
          for(let i = 0;i<this.products.items.length;i++)
          {
              // selectedAddonData.push(this.products.items[2].addOns)
              for(let j = 0;j<this.products.categories.length;j++)
              {        
                if(this.products.items[i].itemCategoryId == this.products.categories[j].categoryId)
                {
                  this.products.items[i].category = this.products.categories[j].name
                }
              }

             let obj :any;
             obj ={ 'Product Name':'','Category':'', Slug:'',Sku:'','Original Price':'','Offer Price':'','Min Qty Per Order':'','Max Qty Per Order':'',
             'Preparation Time':'','Veg or Non Veg':'','Is Inventory Enabled':'','Quantity':'',
             'Description':'','Ingredients':'','Is Testing Catalogue':'' }

             obj['Product Name'] = this.products.items[i].name;
             obj['Category']  = this.products.items[i].category;
             obj['Slug'] = this.products.items[i].slug;
             obj['Sku'] = this.products.items[i].skuNumber;
             obj['Original Price'] = this.products.items[i].costPrice;
             obj['Offer Price'] = this.products.items[i].sellingPrice;
             obj['Min Qty Per Order'] = this.products.items[i].minQuantityPerOrder;
             obj['Max Qty Per Order'] = this.products.items[i].maxQuantityPerOrder;
             obj['Preparation Time'] = this.products.items[i].preparationTimeMinutes;
             obj['Veg or Non Veg'] = this.products.items[i].tag;
             obj['Is Inventory Enabled'] = this.products.items[i].isInventoryEnabled;
             obj['Quantity'] = this.products.items[i].quantityAvailable;
             obj['Description'] = this.products.items[i].description;
             obj['Ingredients'] = this.products.items[i].ingredients;
             obj['Is Testing Catalogue'] = this.products.items[i].isTestingCatalogue;
             selectedProductData.push(obj);
          }
          // console.log(this.products);
          
          console.log(selectedProductData);
          
          this.exportService.exportAsExcelFile(selectedProductData,selectedAddonData,'product-list')      
        }
      
      })
    }
  onFileSelect(event) {
    this.fileErrors = null;
    this.files.push(...event.addedFiles);
  }
   
  onFileRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}

private async readFile(file: File): Promise<string | ArrayBuffer> {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      return resolve((e.target as FileReader).result);
    };

    reader.onerror = e => {
      console.error(`FileReader failed on file ${file.name}.`);
      return reject(null);
    };

    if (!file) {
      return resolve(null);
    }

    reader.readAsDataURL(file);
  });
}
  onImportSubmit() {
    this.serverErrors = [];
    console.log("Import submit called...");
    if(!this.files.length) {
      this.fileErrors = "File is mandatory";
      return;
    }
    this.ngLoader = true;
    var files = this.files;
    var file = files[0];
   
    const formData = new FormData(); 
    var fileName = file.name;
    formData.append('file', file, fileName);  
    this.ApiService.importData(formData).subscribe((res: any)=> {
      this.ngLoader =  false;
      if(res.success) { 
        this.toastr.success(res.message);
      } else { 
        this.toastr.error("Error in Import");
        this.serverErrors = res.errors;
        console.log("Error is " +  JSON.stringify(res));
      }

    },(error)=> {
      this.ngLoader =  false;
      this.toastr.error("Error in import");
      console.log(JSON.stringify(error));
    });      
   
       
  }
}

import { Component, OnInit } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { customRegexValidator } from "../../helper/validator";
import { regEx } from "../../helper/constants";
@Component({
  selector: "app-user-roles",
  templateUrl: "./user-roles.component.html",
  styleUrls: ["./user-roles.component.scss"],
})
export class UserRolesComponent implements OnInit {
  modalReference: NgbModalRef;
  rolesForm: FormGroup;
  submitted: boolean = false;
  clicked: boolean = false;
  edit: boolean = false;
  create: boolean = false;
  rolesArray;
  usersArray;
  userData;
  formData;
  id = JSON.parse(localStorage.getItem("restaurantId"));
  page = 1;
  perPage = 10;
  pagination: any;
  totalItems;
  userId;
  totalCount: any;
  get ordersFormArray() {
    return this.rolesForm.controls.roles as FormArray;
  }
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ApiService: ApiService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  handleRestaurantRegistration() {
    var token = localStorage.getItem("UserToken");
    var isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }
    if (!isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    var id = localStorage.getItem("restaurantId");
    if (id) {
      id = JSON.parse(id);
    }
    if (id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(["get-started"]);
      return false;
    }
    return true;
  }
  ngOnInit() {
    this.rolesForm = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          customRegexValidator({
            pattern: regEx.nameRegEx,
            msg: "Invalid Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Name cannot start or end with a blank space",
          }),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: regEx.emailRegEx,
            msg: "Invalid Email Format",
          }),
        ],
      ],
      mobileNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15),
          customRegexValidator({
            pattern: regEx.phoneNumberWithoutCodeRegEx,
            msg: "Invalid Phone Number",
          }),
        ],
      ],
      password: [
        "",
        [
          Validators.minLength(6),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Password Cannot start with blank space",
          }),
        ],
      ],
      roles: new FormArray([]),
    });
    var ok = this.handleRestaurantRegistration();
    if (!ok) {
      return;
    }
    this.getRole();
    this.getRestaurantUsers();
  }

  displayErrorsAndSuccess(result) {
    if (result["success"] == 1) {
      this.toastr.success(result.message);
      this.modalReference.close();
    } else {
      return this.toastr.warning(result.message);
    }
  }

  getRestaurantUsers() {
    this.ApiService.getRestaurantUsers(
      this.id,
      this.page,
      this.perPage
    ).subscribe((result) => {
      this.usersArray = result["items"];
      this.pagination = true;
      this.page = result["totalPages"];
      this.totalCount = result["count"];

      console.log(result);
    });
  }

  pageChange(page) {
    this.page = page;
    this.ApiService.getRestaurantUsers(
      this.id,
      this.page,
      this.perPage
    ).subscribe((result) => {
      this.totalItems = result["totalItems"];
      this.usersArray = result["items"];
    });
  }

  getRole() {
    this.ApiService.getRoles().subscribe((result) => {
      this.rolesArray = result["items"];
      console.log("Roles array is ");
      console.log(this.rolesArray);
      this.rolesArray.forEach(() =>
        this.ordersFormArray.push(new FormControl(false))
      );
    });
  }

  get f() {
    return this.rolesForm.controls;
  }
  //create form?
  open(content) {
    this.create = true;
    this.edit = false;
    this.rolesForm.reset();
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {}
    );
  }
  //edit form
  editUser(content, id) {
    this.userId = id;
    this.create = false;
    this.edit = true;
    this.modalReference = this.modalService.open(content, {
      size: "",
      centered: true,
      windowClass: "modal-custom",
    });
    this.modalReference.result.then(
      (result) => {},
      (reason) => {}
    );
    this.ApiService.getUserData(id).subscribe((result) => {
      this.userData = result["restaurantUserData"];
      console.log(this.userData);
      this.rolesForm.patchValue({
        name: this.userData.name,
        email: this.userData.email,
        mobileNumber: this.userData.mobileNumber,
        roles: this.userData.roles,
      });
    });
  }

  rolesSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.rolesForm.invalid) {
      this.clicked = false;
      return;
    }
    const roles = this.rolesForm.value.roles
      .map((checked, i) => (checked ? this.rolesArray[i].id : null))
      .filter((v) => v !== null);
    // console.log('selectedOrderIds');
    // console.log(selectedOrderIds);
    // console.log('selectedOrderIds');
    // if(selectedOrderIds.length == 0 ) {
    //   this.toastr.error('Select atleast one user role to proceed')
    //   return
    // }
    this.formData = {
      name: this.rolesForm.value.name,
      email: this.rolesForm.value.email,
      mobileNumber: this.rolesForm.value.mobileNumber,
      password: this.rolesForm.value.password,
      roles: roles,
    };
    console.log();
    
    console.log(`Data is`, this.formData);
    if (this.edit) {
      this.ApiService.updateUserData(this.userId, this.formData).subscribe(
        (result) => {
          this.displayErrorsAndSuccess(result);
          this.rolesForm.reset();
          this.getRestaurantUsers();
        }
      );
    } else {
      console.log(this.formData);
      this.ApiService.createUser(this.formData, this.id).subscribe((result) => {
        this.displayErrorsAndSuccess(result);
        this.submitted = false;
        //this.rolesForm.reset();
        this.getRestaurantUsers();
      });
    }
  }

  deleteUser(id) {
    this.userId = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.confirm();
      }
    });
  }

  confirm() {
    this.ApiService.deleteUser(this.userId).subscribe((result) => {
      this.displayErrorsAndSuccess(result);
      this.getRestaurantUsers();
    });
  }
}

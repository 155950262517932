import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  analatycsForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  restaurantData;
  analytics;
  id;
  ngLoader;
  restaurantId = JSON.parse(localStorage.getItem('restaurantId'));
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService, 
    private router: Router) { }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return;
    }
    var id = localStorage.getItem('restaurantId');
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return;
    }
  } 
  ngOnInit() {
    this.handleRestaurantRegistration();
    this.analatycsForm = this.fb.group({
      googleAnalyticsId: [''],
      googleTagManagerId: [''],
      facebookPixelId: ['']
    })
    this.getAnalatycsData();
  }

  getAnalatycsData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe(result => {
      this.ngLoader = false;
      this.restaurantData = result['item'];
      this.analatycsForm.patchValue({
        googleAnalyticsId: this.restaurantData.analytics.googleAnalyticsId,
        googleTagManagerId: this.restaurantData.analytics.googleTagManagerId,
        facebookPixelId: this.restaurantData.analytics.facebookPixelId
      })
    })
  }

  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
      this.toastr.warning(result.message)
      }
  }

  onSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.analatycsForm.invalid) {
      this.clicked = false;
      return;
    }
    this.analytics = {
      analytics: this.analatycsForm.value
    };
    this.ngLoader = true;
    this.ApiService.setupRestaurantsUpdate(this.restaurantId, this.analytics).subscribe(result => {
      this.displayErrorsAndSuccess(result);
      this.ngLoader = false;
     })
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dynamic-script',
  templateUrl: './dynamic-script.component.html',
  styleUrls: ['./dynamic-script.component.scss']
})
export class DynamicScriptComponent implements OnInit {
  dynamicScriptForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  restaurantData;
  restaurantId = JSON.parse(localStorage.getItem('restaurantId'));
  id;
  ngLoader;
  constructor(private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService, private router: Router) { }

  handleRestaurantRegistration() {
    var token =  localStorage.getItem('UserToken');
    var isLoggedIn = false;
    if(token) {
      isLoggedIn = true;
    }
    if(!isLoggedIn) { 
      this.router.navigate(['login']);
      return false;
    }
    var id = localStorage.getItem('restaurantId');
    if(id) {
      id = JSON.parse(id);
    }
    if(id) {
      this.id = id;
    } else {
      console.log("No id found");
      this.router.navigate(['get-started']);
      return false;
    }
    return true;
  } 
  ngOnInit() {
    var ok = this.handleRestaurantRegistration();
    this.dynamicScriptForm = this.fb.group({
      dynamicScript: ['']
    }) 
    if(!ok) {
      return;
    }
    this.getDynamicScriptData();
  }

  displayErrorsAndSuccess(result) {
    if(result['success'] == 1) {
      this.toastr.success(result.message);
      } else {
      this.toastr.warning(result.message)
      }
  }

  getDynamicScriptData() {
    this.ngLoader = true;
    this.ApiService.getRestaurantsSetup(this.restaurantId).subscribe(result => {
      this.ngLoader = false;
      this.restaurantData = result['item'];
      this.dynamicScriptForm.patchValue({
        dynamicScript: this.restaurantData.dynamicScript
      })
    })
  }

  onSubmit() {
    this.submitted = true;
    this.clicked = true;
    if (this.dynamicScriptForm.invalid) {
      this.clicked = false;
      return;
    } 
    this.ngLoader = true;
    this.ApiService.setupRestaurantsUpdate(this.restaurantId, this.dynamicScriptForm.value).subscribe(result => {
     this.displayErrorsAndSuccess(result);
     this.ngLoader = false;
    })
  }

}

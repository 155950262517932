import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted:boolean = false;
  clicked:boolean = false;
  UserToken: any;
  UserDetails: any;
  userLogged: any;
  userRoles;
  arrayStrings : string[] = [];
  ngLoader: boolean = false;
  constructor(
    private ApiService:ApiService,
    private toastr: ToastrService, 
    private fb: FormBuilder, 
    private router: Router,) { }



  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required]],
      keepLoggedIn: []
    })

    if(this.ApiService.loggedIn()){
      this.router.navigate(['get-start']) 
    }
    
 
  }
    get f() {
      return this.loginForm.controls;
    }


    onSubmit() {
      console.log("Submit called...");
      this.submitted = true;
      this.clicked = true;
      if (this.loginForm.invalid) {
          console.log("Login form is invalid");
          this.clicked = false;
          return;
      }
      let loginFromData = this.loginForm.value;
      this.ngLoader = true
      console.log("Calling api..");
      this.ApiService.loginSubmit(loginFromData).subscribe((res:any)=>{   
        console.log("Api res");
        console.log(res);
        this.ngLoader = false  	        
        if (res.success == 1) { 
         // console.log(res)
          this.UserToken = res['token']
          this.UserDetails = res['userDetails'] 
          var restaurant =  this.UserDetails.restaurant;
           
          if(restaurant) {
            localStorage.setItem("restaurantId", JSON.stringify(restaurant.id));
            localStorage.setItem("slug",  restaurant.slug);
            localStorage.setItem("restaurantName", restaurant.slug); 
          }
          console.log("User roles from user detaails are ------------------------------------------")
          console.log(this.UserDetails["roles"])
          if(this.UserDetails['roles'].length > 0){
            this.userRoles = this.UserDetails['roles'];
            
            this.userRoles.forEach(element => {
              console.log(`Element.name is ${element.name}`)
              element.name = element.name.trim();
              this.arrayStrings.push(element.name)
              if( element.name  == 'superadmin'){
                this.router.navigate(['restaurant-list'])
              } else
              if( (element.name  == 'admin') || (element.name  == 'Admin')){
                //   if(this.UserDetails.restaurantId){
                //   this.router.navigate(['home'])
                // }else{
                  this.router.navigate(['get-start'])
                // }
              } else
              if( element.name  == 'subAdmin' || element.name == "Sub Admin"){
                this.router.navigate(['get-start'])
              } else
              if( element.name  == 'marketing' || element.name == "Marketing"){
                this.router.navigate(['promo-codes'])
              } else
              if( element.name  == 'orderView' || element.name == "Order View Only"){
                this.router.navigate(['/order-list'])
              } else
              if( element.name  == 'products'|| element.name == "View Products"){
                this.router.navigate(['/products-list'])
              } else
              if( element.name  == 'customers'|| element.name == "Customers"){
                this.router.navigate(['/customers'])
              } else
              if( element.name  == 'configuration'|| element.name == "Configuration"){
                this.router.navigate(['/user-roles'])
              } else{
                this.router.navigate(['/notFound'])
              } 

            });            
 
             localStorage.setItem('userRoles', JSON.stringify(this.arrayStrings));               
             localStorage.setItem("UserDetails", JSON.stringify(this.UserDetails));
             localStorage.setItem("UserToken", this.UserToken); 
             this.toastr.success(res.message);          
          }else{
            this.toastr.error('Permission Denied');
          }     

                
        } else {
          this.toastr.warning(res.message);
        }
      }, error => {
        console.log("API error");
        console.log(error);
        this.ngLoader = false
      })
    }



 

}

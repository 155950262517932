import { Component, OnInit, Input} from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss']
})
export class AreaListComponent implements OnInit { 
  page = 1;
  perPage = 100;
  ngLoader = false;
  areas = [];
  @Input() emirateId: string;
  edit = false;
  submitted;
  inputStaticCharges;
  modalReference;
  areaForm;
  clicked;
  areaId;
  currentPage;
  totalPages;
  areaObj;
  isEnabled;
  falseValue = false
  constructor(private modalService: NgbModal, private fb: FormBuilder, private ApiService: ApiService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }


  ngOnInit() {
    
    this.areaForm = this.fb.group({ 
      name: ['', Validators.required],
      isEnabled: [true, []],
    })
    
    this.route.params.subscribe(params => {
      this.emirateId = params.id;   
      this.listAreas();
    });
  }
  get f1() {
    return this.areaForm.controls;
  }
  
  saveArea() {
    console.log("Save area is called.");
    this.submitted = true;
    this.clicked = true;
    if (this.areaForm.invalid) {
      console.log("Area form is invalid...");
      this.clicked = false;
      return;
    } 
    var data = this.areaForm.value;
    data.parentEmirateId = this.emirateId;
    if (this.edit) {
      this.ngLoader = true 
      this.ApiService.updateArea(data, this.areaId).subscribe(result => {
        this.ngLoader = false
        this.toastr.success('Successfully updated');
        this.modalReference.close();
        this.areaForm.reset();
        this.listAreas();
      }, error => {
        this.ngLoader = false
      })
    } else {
      this.ngLoader = true
      this.ApiService.createArea(data).subscribe(result => {
        this.ngLoader = false
        this.toastr.success('Successfully saved');
        this.modalReference.close();
        this.areaForm.reset();
        this.listAreas();
      }, error => {
        this.ngLoader = false
      })
    }
  }
  listAreas( ) { 
    this.page = 1,
    this.perPage = 80;
    this.ngLoader = true
    this.ApiService.getAreaListPage(this.emirateId, this.page, this.perPage).subscribe(result => { 
      this.currentPage = result['page'];
      this.totalPages = result['totalPages'];
      this.ngLoader = false
      this.areas = result['items'];
      console.log("Areas list received are")
      console.log(this.areas)
    }
      , error => {
        this.ngLoader = false
        this.toastr.error(error.error.message);
      })
  }
  
  open(content, isEdit) {
    this.edit = false;
    if(!isEdit) {      
    this.areaForm.reset();
    this.areaForm.patchValue({ isEnabled: true });
    }
    this.submitted = false;
    this.inputStaticCharges = false;
    this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    this.modalReference.result.then((result) => {
    }, (reason) => {
    }); 
  }
  
  editArea(content, id) {
    this.areaId = id;
    // this.modalReference = this.modalService.open(content, { size: '', centered: true, windowClass: 'modal-custom' });
    // this.modalReference.result.then((result) => {
    // }, (reason) => {

    // });
    if (this.areaId) {
      this.ngLoader = true
      this.ApiService.getAreaData(this.areaId).subscribe(result => {
        this.ngLoader = false
        this.areaObj = result['item'];
        this.areaForm.patchValue({
          name: this.areaObj.name,
          isEnabled: this.areaObj.isEnabled
        })
        if (this.areaObj.isEnabled == true) {
          this.isEnabled = true;
        }
        this.open(content, true);
        this.edit = true;
      }, error => {
        this.ngLoader = false
      })
    }
  }

  deleteArea() {
    this.ngLoader = true;
    this.ApiService.deleteArea(this.areaId).subscribe(result => {
      this.ngLoader = false;
      this.toastr.success('Successfully deleted');
      this.listAreas();
    })
  }
  showDeleteConfirmationPopup(id) {
    this.areaId = id;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteArea();
      }
    })
  }
}
